/* You can add global styles to this file, and also import other style files */
/* animate.css */
@import 'node_modules/animate.css/animate.min.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.button_language {
  background-color: #e3e1e1;
  color: #000;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errors {
  --color: rgb(230, 138, 0);
  --colortitle: rgb(230, 138, 0);
  --colorText: rgb(230, 138, 0);
}
.non {
  --color: rgba(0, 0, 0, 0.253);
  --colortitle: rgba(0, 0, 0, 0.877);
  --colorText: rgb(0, 0, 0);
}
